import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import profileData from '../data/profile.json'; // Assuming profileData is loaded from a JSON file
import projectData from '../data/projects.json'; // Assuming projectData is loaded from a JSON file
import { DialogComponent } from '../AddOnComponents/dialog/dialog.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../app.component.css', './home.component.css']
})
export class HomeComponent implements OnInit {
  public profileData: any;
  public projectData: any;
  public itemIntro: string;
  public currentIndex: number;
  public UserName:string = "";
  public currentIndex2 = 0;

  constructor(
    private appservice: AppService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.profileData = profileData;
    this.projectData = projectData;
    this.itemIntro = '';
    this.currentIndex = 0;
    this.currentIndex2 = 0;
    

    // Check if there are announcements and open a dialog if needed
    if (profileData.announce.length !== 0) {
      this.openDialog(profileData.announce);
    }
  }

  ngOnInit(): void {
    this.typeIntro(); // Call method to start typing animation on component initialization
  }

  // Method to open dialog with announcement data
  openDialog(announcement: string[]) {
    this.dialog.open(DialogComponent, {
      data: announcement,
    });
  }

  // Method to toggle project data and navigate to project component
  toggleProject(val) {
    if (val === 'webprojects') {
      this.appservice.setProjectData(projectData['webProject']);
    } else if (val === 'androidprojects') {
      this.appservice.setProjectData(projectData['androidProject']);
    } else if (val === 'softwareprojects') {
      this.appservice.setProjectData(projectData['softwareProject']);
    }

    this.router.navigate(['/projects', { category: val }]);
  }

  // Method to simulate typing animation for introduction text
  typeIntro(): void {
    const text = this.profileData.intro.join("\n"); // Combine intro array into a single string
  
    const interval = setInterval(function () {
      if (this.currentIndex < text.length) {
        this.itemIntro += text.charAt(this.currentIndex);
        this.currentIndex++;
      } else {
        clearInterval(interval);
      }
    }.bind(this), 5);

    const userName = this.profileData.username;
    const intervalU = setInterval(function () {
      if (this.currentIndex2 < userName.length) {
        this.UserName += userName.charAt(this.currentIndex2);
        this.currentIndex2++;
      } else {
        clearInterval(intervalU);
      }
    }.bind(this), 50);

  }


  
}
