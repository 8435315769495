import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'raghuram07 - Portfolio';
}
//TODO : 
// Highlight the feild in the nav bar based on the section 
// Education and experience section time line laaga vundali
// Projects display marchali
