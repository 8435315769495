import { Component, OnInit } from '@angular/core';
import profileData from '../../data/portfolio.json'; // Assuming profileData is loaded from a JSON file

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})
export class ProfilesComponent implements OnInit {
  profileData: any;
  dataArray: any[] = [];
  currentSelection: string = 'featured';
  featuredData: any[] = [];
  

  constructor() {
    this.profileData = profileData;
    this.featuredData = [...profileData.certificates.slice(0,1),...profileData.projects.slice(0,3),...profileData.programmingProfiles.slice(0,2)];
    this.modifyMainArray(this.currentSelection);
   }

   modifyMainArray(criteria: string): void {
   switch(criteria) {
    case 'all':
      this.dataArray = [...this.profileData.projects,...this.profileData.certificates,...this.profileData.programmingProfiles];
      break; 
    case 'featured':
      this.dataArray = this.featuredData;
      break;
    default:  
      this.dataArray = this.profileData[criteria]
   }
    
    this.currentSelection = criteria;
  }

  ngOnInit() {
  }

}
