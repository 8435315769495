import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import projectData from '../../data/projects.json';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  public projectData;
  public OpenedProjectType;
  public OpenedProject;

  constructor(
    private appservice: AppService
  ) {
    this.projectData = projectData
  }

  ngOnInit() {
    console.log("Im at ProjectsComponent")
   // this.projectData = this.appservice.getProjectData();
    console.log("This is the projecData",this.projectData);
  }
  expandPannel(projectType){
    this.OpenedProjectType=projectType
  }
  expandProject(project){
    this.OpenedProject = project
  }

}
