import { Component, OnInit } from '@angular/core';
import SkillsData from '../../data/skills.json';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {

  public skillsData;
  public skillSet1;
  public skillSet2;
  constructor() { 
    let halfLen = Math.ceil(SkillsData.skills.length/2);
    this.skillSet1 = SkillsData.skills.slice(0,halfLen);
    this.skillSet2 = SkillsData.skills.slice(halfLen,);
    this.skillsData = SkillsData
  }

  ngOnInit() {
  }

}
