import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  public showNavbar: boolean = false;
  currentSection = 'about'; 
  constructor() { }

  ngOnInit() {
  }
  
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    const verticalOffset =  document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.showNavbar = verticalOffset > 100;

    const sections = ['contact', 'education', 'experience', 'profile', 'skills', 'about'];
    const currentOffset = window.pageYOffset + 400; // Adding 50 to account for navbar height or any offset

    for (let i = sections.length - 1; i >= 0; i--) {
      const section = sections[i];
      const element = document.getElementById(section);
      if (element && currentOffset >= element.offsetTop) {
        this.currentSection = section;
      }
    }
  }



}
