import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SidebarComponent } from './AddOnComponents/sidebar/sidebar.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { ProjectsComponent } from './sections/projects/projects.component';
import { AppService } from './app.service';
import {MatTooltipModule} from  '@angular/material/tooltip';
import {MatExpansionModule} from '@angular/material/expansion';
import { DialogComponent } from './AddOnComponents/dialog/dialog.component';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { SkillsComponent } from './sections/skills/skills.component';
import { ContactComponent } from './sections/contact/contact.component';
import { ProfilesComponent } from './sections/profiles/profiles.component';
import { SkillCardComponent } from './AddOnComponents/skill-card/skill-card.component';
@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HomeComponent,
    ProjectsComponent,
    DialogComponent,
    SkillsComponent,
    ContactComponent,
    ProfilesComponent,
    SkillCardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatDialogModule,
    MatButtonModule
  ],
  entryComponents: [DialogComponent],
  exports:[MatTooltipModule],
  providers: [AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }
